(function ($) {
    'use strict';
    $(function () {

        $(document).ready(function () {

            $('.nav-button').on('click', function () {
                if ($(this).hasClass('opened')) {
                    $('.nav-ul').toggleClass('hidden', true);
                    $('.nav-button').toggleClass('opened', false);
                    $('.body').toggleClass('still', false);
                    setTimeout(function () {
                        $("#nav").toggleClass('opened', false);
                        $('.header-title').toggleClass('opened', false);
                        $('.header-logo').toggleClass('opened', false);
                    }, 50)

                } else {
                    $('.nav-button').toggleClass('opened', true);
                    $("#nav").toggleClass('opened', true);
                    $('.header-title').toggleClass('opened', true);
                    $('.header-logo').toggleClass('opened', true);
                    window.scrollTo(0, 0);
                    setTimeout(function () {
                        $('.body').toggleClass('still', true);
                        $('.nav-ul').toggleClass('hidden', false);
                    }, 500)
                }
            })

            $(document).on('click', function (event) {
                var target = $(event.target);
                if (target.hasClass('load-more')) {
                    var page = target.data('next_page')

                    $.ajax(
                        {
                            url: ajax_url,
                            type: 'post',

                            data: {
                                'action':
                                    'more_posts',
                                'page':
                                page
                            },
                            success: function (response) {
                                response = JSON.parse(response);
                                $('.pagination').remove();
                                $('#events').append(response.html)
                            }
                        }
                    );
                }
            });

        });
    });
})(jQuery);

function fix()
{
    var el = this;
    setTimeout(function() {$(el).addClass('mobile-cancel');}, 500)
}